<template>
  <!-- 进件结果页面(失败) -->
  <div class="container u-flex-col u-col-center">
    <img src="../assets/images/icon_trial_fail_1.png" />
    <span class="text_status">您的借款申请提交成功</span>
    <div class="line"></div>
    <div class="div-amount-time u-flex u-row-between">
      <span class="at-text-left">借款金额</span>
      <span class="at-text-right">￥{{ this.form.loanAmount }}</span>
    </div>
    <div class="div-amount-time u-flex u-row-between">
      <span class="at-text-left">借款时间</span>
      <span class="at-text-right">{{ this.curTime }}</span>
    </div>
    <span class="text_hint"
      >本平台所有服务免费，以官方名义收费或在服务签收费的行为，均为诈骗！</span
    >
    <div class="btn-agree">
      <span class="text_agree u-flex u-row-center" @click="handleDownload"
        >同意，去APP激活额度({{ this.countDown }})</span
      >
    </div>
  </div>
</template>
<script>
import * as API_Common from "@/api/common.js";
export default {
  name: "TrialSuccessTl9",
  data() {
    return {
      constants: {},
      form: {
        channelNo: this.$route.query.source, // 渠道标识
        loanAmount: this.$route.query.amount ? this.$route.query.amount : 0, // 借款金额
      },
      curTime: "", // 当前时间
      countDown: 5, // 倒计时5秒
      timer: null, // 计时器
    };
  },
  mounted() {
    this.getCurrentTime();
    this.getDownloadUrl();
    this.timer = setInterval(() => {
      this.countDown--;
      if (this.countDown === 0) {
        clearInterval(this.timer);
        this.handleDownload();
      }
    }, 1000);
  },
  methods: {
    // 下载按钮点击
    handleDownload() {
      let isWeixin = this.isWeixin();
      if (isWeixin) {
        this.showPop = true;
      } else {
        if (this.brand === "xiaomi") {
          window.location.href = "https://app.xiaomi.com/detail/1500555";
        } else {
          setTimeout(() => {
            window.location.href = this.downAppUrl;
          }, 50);
        }
      }
    },

    // 获取下载地址
    getDownloadUrl() {
      API_Common.getDownloadUrl(this.form.channelNo, true).then((res) => {
        if (res.code === 200) {
          this.downAppUrl = res.data.url;
        } else {
          this.$toast.fail("获取下载地址失败,请重试");
        }
      });
    },

    // 获取当前时间
    getCurrentTime() {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      this.curTime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 0.45rem;

  > img {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 0.84rem;
  }

  .text_status {
    overflow-wrap: break-word;
    color: rgba(34, 34, 34, 1);
    font-size: 0.32rem;
    font-family: SourceHanSansCN-Medium;
    font-weight: 500;
    white-space: nowrap;
    line-height: 0.4rem;
    margin-top: 0.29rem;
  }

  .line {
    width: 100%;
    height: 0.01rem;
    background: #d7d7d7;
    margin-top: 1.2rem;
  }

  .div-amount-time {
    width: 100%;
    margin-top: 0.43rem;

    .at-text-left {
      overflow-wrap: break-word;
      color: rgba(34, 34, 34, 1);
      font-size: 0.28rem;
      font-family: SourceHanSansCN-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.4rem;
    }

    .at-text-right {
      overflow-wrap: break-word;
      color: rgba(136, 136, 136, 1);
      font-size: 0.28rem;
      font-family: SourceHanSansCN-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.4rem;
      margin-top: 0.03rem;
    }
  }

  .text_hint {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 0.24rem;
    color: #999999;
    line-height: 0.4rem;
    margin-top: 0.54rem;
  }

  .btn-agree {
    background-color: rgba(251, 107, 98, 1);
    border-radius: 0.5rem;
    height: 1rem;
    width: 100%;
    margin-top: 0.73rem;

    .text_agree {
      height: 1rem;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 0.32rem;
      font-family: SourceHanSansCN-Bold;
      font-weight: 700;
      white-space: nowrap;
    }
  }
}
</style>
